var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { position, height, boxShadow, backgroundColor } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import { usePromoEvents } from '../../common/hooks/dynamic-yield';
import { useIsObserved } from '../../common/hooks/intersection-observer';
import { textDecoration } from '../../common/theme/system-utilities';
import { InformationBox } from './InformationBox';
import { SlideImage } from './SlideImage';
var TopTeaserContainer = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", "\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, height, boxShadow, backgroundColor, textDecoration);
TopTeaserContainer.defaultProps = {
    as: 'a',
    position: 'relative',
    flexDirection: ['column', 'row'],
    justifyContent: 'flex-end',
    height: [368, 220, 304, 394],
    width: 1,
    backgroundColor: 'sys.neutral.background.hover',
    textDecoration: 'none',
    boxShadow: ['0 2px 6px 0 #c0c0c0', 'none'],
};
var HomepageTopTeaser = function (_a) {
    var imageProps = _a.image, campaignDuration = _a.campaignDuration, countDown = _a.countDown, title = _a.title, subtitle = _a.subtitle, cta = _a.cta, fullWidthImage = _a.fullWidthImage, informationBoxBg = _a.informationBoxBg, informationBoxColor = _a.informationBoxColor, legalDisclaimer = _a.legalDisclaimer, tracking = _a.tracking;
    var _b = __read(useIsObserved(), 2), ref = _b[0], isObserved = _b[1];
    var _c = usePromoEvents(tracking), onPromoView = _c.onPromoView, onPromoClick = _c.onPromoClick;
    useEffect(function () {
        if (isObserved) {
            onPromoView();
        }
    }, [isObserved, onPromoView]);
    return (React.createElement(TopTeaserContainer, __assign({ ref: ref, "data-testid": "homepage-top-teaser-container", type: cta.type, href: cta.href, bg: informationBoxBg, onClick: onPromoClick }, (isObserved && {
        'data-prefetch': true,
    })),
        React.createElement(InformationBox, { title: title, subtitle: subtitle, campaignDuration: campaignDuration, countDown: countDown, cta: cta, fullWidthImage: fullWidthImage, informationBoxBg: informationBoxBg, informationBoxColor: informationBoxColor }),
        React.createElement(SlideImage, { image: imageProps, legalDisclaimer: legalDisclaimer, fullWidthImage: fullWidthImage })));
};
export default HomepageTopTeaser;
var templateObject_1;
