var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { lineHeight, fontSize, maxWidth, order, space } from 'styled-system';
import CountDown from '../../common/components/CountDown';
import { Flex } from '../../common/components/Flex';
import { Text } from '../../common/components/Text';
export var EllipsisParagraph = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])), space, maxWidth, order);
EllipsisParagraph.defaultProps = {
    mt: 0,
    maxWidth: ['300px', null, '400px', '550px'],
};
var CampaignDuration = styled(EllipsisParagraph)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), lineHeight, fontSize, space);
CampaignDuration.defaultProps = __assign(__assign({}, EllipsisParagraph.defaultProps), { as: 'p', margin: 0, lineHeight: ['lh_16', 'lh_24'], fontSize: ['fs_14', 'fs_16', null, 'fs_18'] });
var CampaignInfoContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  ", ";\n  ", ";\n"], ["\n  flex: 1;\n  ", ";\n  ", ";\n"])), space, order);
CampaignInfoContainer.defaultProps = {
    order: [3, 1],
};
var TopTeaserCountDown = styled(CountDown)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), space, fontSize, lineHeight);
TopTeaserCountDown.defaultProps = {
    mr: ['sp_8', 'sp_8', 'sp_40'],
    lineHeight: ['lh_16', 'lh_24'],
    fontSize: ['fs_14', 'fs_16', null, 'fs_18'],
};
var CampaignInfo = function (_a) {
    var duration = _a.duration, countDown = _a.countDown;
    return (React.createElement(CampaignInfoContainer, { "data-testid": "campaign-info" },
        duration && (React.createElement(CampaignDuration, { "data-testid": "campaign-duration" }, duration)),
        React.createElement(Flex, { flexWrap: "wrap" }, (countDown === null || countDown === void 0 ? void 0 : countDown.endDate) && (React.createElement(TopTeaserCountDown, { label: countDown.label, endDate: countDown.endDate })))));
};
export default CampaignInfo;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
