var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { space, color, display, fontSize, position, themeGet, } from 'styled-system';
import { Button } from '../../common/components/Button';
import { themeRight, cursor, textDecoration, themeBottom, themeTop, } from '../../common/theme/system-utilities';
var LegalNotesLinkContainer = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &::before {\n    ", ";\n    content: '*';\n    padding-right: ", "px;\n  }\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &::before {\n    ", ";\n    content: '*';\n    padding-right: ", "px;\n  }\n\n  &:hover {\n    color: ", ";\n  }\n"])), themeRight, fontSize, position, themeTop, themeBottom, color, space, textDecoration, cursor, display, themeGet('space.sp_4'), themeGet('colors.sys.primary.background.default'));
LegalNotesLinkContainer.defaultProps = {
    kind: 'bare',
    fontSize: 'fs_14',
    themeRight: 'sp_0',
    themeTop: 'auto',
    themeBottom: 'sp_0',
    p: 'sp_8',
    position: 'absolute',
    display: 'inline-block',
    textDecoration: 'underline',
    cursor: 'pointer',
};
var LegalNotesLink = function (_a) {
    var linkLabel = _a.linkLabel, linkLabelColor = _a.linkLabelColor, onClick = _a.onClick;
    return (React.createElement(LegalNotesLinkContainer, { color: linkLabelColor, onClick: onClick }, linkLabel));
};
LegalNotesLink.defaultProps = {
    onClick: function () { return undefined; },
};
export default LegalNotesLink;
var templateObject_1;
