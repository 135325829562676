var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { height, order, position, width } from 'styled-system';
import PrismicResponsiveImage from '../../common/components/PrismicResponsiveImage';
import LegalDisclaimer from './LegalDisclaimer';
var SLIDE_SECOND_HALF_WIDTHS = [1, '52%', null, '57%'];
var SlideContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), width, order);
SlideContainer.defaultProps = {
    order: [1, 2],
};
var ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), position, height);
ImageContainer.defaultProps = {
    position: 'relative',
    height: [168, 220, 304, 394],
};
var Image = styled(PrismicResponsiveImage)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), height, width);
Image.defaultProps = {
    width: 1,
    height: '100%',
};
export var SlideImage = function (_a) {
    var image = _a.image, legalDisclaimer = _a.legalDisclaimer, fullWidthImage = _a.fullWidthImage;
    return (React.createElement(SlideContainer, { "data-testid": "right-side-slide", width: fullWidthImage ? '100%' : SLIDE_SECOND_HALF_WIDTHS },
        React.createElement(ImageContainer, { "data-testid": "image-container-slide" },
            React.createElement(Image, { image: image, highPriority: true }),
            legalDisclaimer && React.createElement(LegalDisclaimer, __assign({}, legalDisclaimer)))));
};
var templateObject_1, templateObject_2, templateObject_3;
