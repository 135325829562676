var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { position, space, fontSize, display, flexDirection, justifyContent, alignItems, width, height, boxShadow, order, themeGet, zIndex, top, left, right, bottom, lineHeight, } from 'styled-system';
import { Button } from '../../common/components/Button';
import { breakpoint } from '../../common/theme/helper';
import { boxSizing } from '../../common/theme/system-utilities';
import CampaignInfo, { EllipsisParagraph } from './CampaignInfo';
import { ButtonType } from './types';
var InformationBoxContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  color: ", ";\n  background-color: ", ";\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  color: ", ";\n  background-color: ", ";\n\n  ", "\n"])), position, top, left, right, bottom, display, boxSizing, flexDirection, justifyContent, alignItems, order, width, height, space, zIndex, function (props) {
    return props.informationBoxColor || themeGet('colors.sys.neutral.border.active');
}, function (_a) {
    var bg = _a.bg;
    return bg || themeGet('colors.sys.neutral.background.hover');
}, function (props) {
    return props.fullWidthImage && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", " {\n        background-color: transparent;\n      }\n    "], ["\n      ", " {\n        background-color: transparent;\n      }\n    "])), breakpoint(0)(props));
});
InformationBoxContainer.defaultProps = {
    position: ['static', 'absolute'],
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    boxSizing: 'border-box',
    width: [1, '48%', null, '43%'],
    height: ['200px', 'auto'],
    pl: [0, '40px', 6, 7],
    pr: [0, 'sp_16'],
    py: [3, null, '28px', '66px'],
    flexDirection: 'column',
    justifyContent: ['flex-start', 'space-between'],
    alignItems: ['center', 'flex-start'],
    order: [2, 1],
    zIndex: 1,
};
var Title = styled(EllipsisParagraph)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  margin-bottom: ", "px;\n\n  ", " {\n    margin-bottom: 0;\n  }\n"], ["\n  ", ";\n  margin-bottom: ", "px;\n\n  ", " {\n    margin-bottom: 0;\n  }\n"])), order, function (props) { return (props.withSubtitle ? 0 : 32); }, breakpoint(0));
Title.defaultProps = __assign(__assign({}, EllipsisParagraph.defaultProps), { as: 'h2', mx: 0, fontSize: ['22px', null, '30px', 8], lineHeight: [4, null, 5, 6], order: [1, 2], maxWidth: '100%' });
var Subtitle = styled(EllipsisParagraph)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), order, fontSize, lineHeight, space);
Subtitle.defaultProps = __assign(__assign({}, EllipsisParagraph.defaultProps), { as: 'p', fontSize: ['fs_16', null, 'fs_18', '22px'], lineHeight: ['lh_16', 'lh_20', null, 'lh_22'], mt: [1, null, 2, 3], order: [2, 3], maxWidth: '100%' });
var StyledButton = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  /* The !important is because of Shop-Reboot :'( */\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  /* The !important is because of Shop-Reboot :'( */\n  ", ";\n"])), order, space, width, fontSize, justifyContent, boxShadow, themeGet('colors.sys.primary.background.default'), function (props) {
    return props.kind === 'secondary' && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      color: ", " !important;\n      background-color: ", ";\n      border: 1px solid ", ";\n\n      &:hover {\n        color: ", " !important;\n        background-color: ", ";\n        border: 1px solid\n          ", ";\n      }\n    "], ["\n      color: ", " !important;\n      background-color: ", ";\n      border: 1px solid ", ";\n\n      &:hover {\n        color: ", " !important;\n        background-color: ", ";\n        border: 1px solid\n          ", ";\n      }\n    "])), themeGet('colors.sys.neutral.border.active')(props), themeGet('colors.sys.neutral.background.default')(props), themeGet('colors.sys.neutral.border.hover')(props), themeGet('colors.sys.primary.background.default')(props), themeGet('colors.sys.neutral.background.default')(props), themeGet('colors.sys.primary.background.default')(props));
});
StyledButton.defaultProps = __assign(__assign({}, Button.defaultProps), { as: 'button', width: '160px', px: 0, py: 2, mt: [1, null, '10px', '18px'], fontSize: 4, justifyContent: 'center', boxShadow: 'none', order: 4 });
export var InformationBox = function (_a) {
    var campaignDuration = _a.campaignDuration, countDown = _a.countDown, title = _a.title, subtitle = _a.subtitle, cta = _a.cta, fullWidthImage = _a.fullWidthImage, informationBoxBg = _a.informationBoxBg, informationBoxColor = _a.informationBoxColor;
    return (React.createElement(InformationBoxContainer, { informationBoxColor: informationBoxColor, bg: informationBoxBg, fullWidthImage: fullWidthImage, "data-testid": "information-box-slide" },
        React.createElement(CampaignInfo, __assign({ countDown: countDown }, (campaignDuration && { duration: campaignDuration }))),
        title && (React.createElement(Title, { withSubtitle: Boolean(subtitle), "data-testid": "title" }, title)),
        subtitle && React.createElement(Subtitle, { "data-testid": "subtitle" }, subtitle),
        React.createElement(StyledButton, { "data-testid": "button", kind: cta.type === ButtonType.Secondary ? 'secondary' : 'primary' }, cta.label)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
