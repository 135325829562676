import React from 'react';
import { useOpenDisclaimer } from '../../common/hooks/drawer';
import LegalNotesLink from './LegalNotesLink';
var LegalDisclaimer = function (_a) {
    var linkLabel = _a.linkLabel, linkLabelColor = _a.linkLabelColor, contentHtml = _a.contentHtml;
    var onClick = useOpenDisclaimer({
        html: contentHtml,
    }, {
        preventDefault: true,
        stopPropagation: true,
    });
    return (React.createElement(LegalNotesLink, { linkLabel: linkLabel, linkLabelColor: linkLabelColor, onClick: onClick }));
};
export default LegalDisclaimer;
